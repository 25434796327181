<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            虚拟网
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" dark small @click="$router.push('/netDetail')">
              <v-icon small dark>mdi-plus</v-icon>添加
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
          </v-card-subtitle>
          <v-card-text>
            <v-data-table :headers="headers" disable-sort :items="networkList" :server-items-length="networkList.length" :loading="loading" hide-default-footer class="elevation-1">
              <template v-slot:item.enabled="{ item }">
                <v-switch v-model="item.enabled" @change="changeStatus(item)"></v-switch>
              </template>
              <template v-slot:item.created_at="{ item }">
                <span>{{$moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}}</span>
              </template>
              <template v-slot:item.option="{ item }">
                <div style="display:flex;justify-content:flex-start">
                  <!-- <v-btn class="mx-2" dark small color="cyan" @click="netDetail(item)">
                    <v-icon x-small dark>mdi-pencil</v-icon>详情
                  </v-btn>
                  <v-btn class="mx-2" dark small color="purple" @click="$router.push({path:'/nodeGroup',query:{id:item.id,name:item.name}})">
                    <v-icon x-small dark>mdi-format-list-bulleted</v-icon>节点组
                  </v-btn>
                  <v-btn class="mx-2" dark small color="indigo" @click="$router.push({path:'/equipment',query:{id:item.id,name:item.name}})">
                    <v-icon x-small dark>mdi-cube</v-icon>设备
                  </v-btn> -->
                  <v-btn class="mx-2" dark small color="teal" @click="$router.push({path:'/orderList',query:{id:item.id,name:item.name}})">
                    <v-icon x-small dark>mdi-format-list-bulleted-square</v-icon>订单
                  </v-btn>
                  <v-btn class="mx-2" dark small color="pink" @click="del(item)">
                    <v-icon x-small dark>mdi-delete-outline</v-icon>删除
                  </v-btn>
                </div>

              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
  // @ is an alias to /src
  import { post, tips, obj } from '@/facade';
  export default {
    data() {
      return {
        loading: true,
        form: {
          uid: obj.uid
        },
        headers: [
          { text: 'ID', value: "id" },
          { text: '名称', value: "name" },
          { text: '状态', value: "enabled" },
          { text: 'TOKEN', value: "token" },
          { text: '创建时间', value: "created_at" },
          { text: '操作', value: 'option' },
        ],
        networkList: [],
      }
    },
    async created() {
      await this.getNetworkList()
    },


    methods: {
      // 获取虚拟网列表
      async getNetworkList() {
        const data = await post('/vnet/network/list', this.form)
        if (data.code == 'ok') {
          this.loading = false;
          this.networkList = data.networks;
        } else {
          this.loading = false;
          tips('error', data.message)
        }
      },
      /**详情 */
      async netDetail(item) {
        this.$router.push({ path: '/netDetail', query: { nid: item.id } })
      },
      /**删除*/
      async del(item) {
        const value = await confirm('即将提交修改，是否继续')
        if (value) {
          const data = await post('/vnet/network/delete', { nid: item.id })
          if (data.code == 'ok') {
            tips('success', '删除成功')
            this.getNetworkList()
          } else {
            tips('error', data.message)
          }
        }

      },
      /**改变虚拟网状态 */
      async changeStatus(item) {
        const form = { nid: item.id, enabled: !!item.enabled }
        const data = await post('/vnet/network/update', form)
        if (data.code == 'ok') {
          tips('success', '更改成功')
          this.getNetworkList()
        } else {
          tips('error', data.message)
          this.getNetworkList()
        }
      },

    },
  }
</script>

<style lang="scss" scoped>
  .code-mirror-div {
    padding: 2px;
    height: 500px;

    .tool-bar {
      top: 20px;
      margin: 30px 2px 0px 20px;
    }

  }
</style>